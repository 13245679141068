 <template>
  <input type="number" :value="value" @input="update" v-bind:class="{invalid : !state}" />
</template>

<script>
export default {
  name: "StyledInputNumber",
  components: {},
  props: {
    value: {
      type: [Number, String]
    },
    state: {
      type: [Boolean, String],
      default: true
    }
  },
  methods: {
    update(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
input {
  --bottom-border-color: var(--kl-menu-color);
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--secondary-color);
  padding: 0.3em;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
input:focus {
  outline: none !important;
  animation-name: example;
  animation-duration: .5s;
  border-bottom: 2px solid var(--bottom-border-color);
}
@keyframes example {
  from {
    border-bottom: 2px solid white;
  }
  to {
    border-bottom: 2px solid var(--bottom-border-color);
  }
}
.invalid {
  border: 1px solid red;
}
</style>